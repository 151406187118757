import axios from 'axios'
import * as types from './types'

export const getSession = () => {
  return axios.get<any>('/api/v2/session')
}

export const getUserById = (id: string) => {
  return axios.get<types.User>(`/api/v2/users/${id}`)
}

export const getOrder = (body: types.OrderPayload) => {
  return axios.get<types.OrderData>(
    `/api/v2/users/${body?.userId}/orders/${body?.orderId}`
  )
}

export const createAffirmCharge = (
  body: types.CreateAffirmChargePaylod,
  pt?: string
) => {
  return axios.post<types.OrderResponse>(
    '/api/v2/payments/affirm/charge',
    body,
    { params: { pt } }
  )
}

export const createBraintreeClientToken = () => {
  return axios.post<types.BraintreeClientTokenResponse>(
    '/api/v2/payments/braintree/clienttoken'
  )
}

export const createBraintreeCharge = (
  body: types.CreateBraintreeChargePayload,
  pt?: string
) => {
  return axios.post<types.OrderResponse>(
    '/api/v2/payments/braintree/charge',
    body,
    { params: { pt } }
  )
}

export const calculateProductPrices = (body: types.CalculatePricesPayload) => {
  return axios.post<types.CalculatePricesResponse>(
    '/api/v2/payments/prices/calculate',
    body
  )
}

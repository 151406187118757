import React, { useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import {
  AddRounded,
  CheckRounded,
  ExpandLess,
  ExpandMore,
} from '@mui/icons-material'
import { useUpsellStyles } from './styles'
import { useUpsellForm } from './UpsellProvider'
import { getCurrencySymbol } from '@talentinc/gatsby-theme-ecom/utils/currency'
import { UpsellIcon, UpsellItemType } from './UpsellIcon'
import { Upsell } from './types'

export const UpsellPurchaseItem: React.FC<{
  data: Upsell
  peers?: string[]
}> = ({ data, peers }) => {
  const { classes, cx } = useUpsellStyles()
  const [showExpand, setShowExpand] = useState(false)
  const { state, dispatch } = useUpsellForm()

  const { plan_code, name, price, short_pitch, icon_url, currency_code } = data

  const handleToggle = () => {
    dispatch({ type: 'TOGGLE_PACKAGE', value: plan_code, peers })
  }

  const isActive = state.activePackages.has(plan_code)

  return (
    <Box
      className={cx(classes.itemContainer, { [classes.itemActive]: isActive })}
    >
      <Box display="flex" flexDirection="row" gap="20px">
        {icon_url && (
          <Box mt="10px">
            <UpsellIcon id={icon_url as UpsellItemType} />
          </Box>
        )}
        <Box
          display="flex"
          flexDirection="column"
          gap="7px"
          style={{ flex: 1 }}
          pt={1}
        >
          <Typography
            style={{
              fontSize: 18,
              fontWeight: 600,
            }}
          >
            {name}
          </Typography>
          <Box
            className={classes.detailsContainer}
            display="flex"
            justifyContent="space-between"
          >
            <Box
              display="flex"
              gap="1px"
              alignItems="center"
              style={{ cursor: 'pointer' }}
              onClick={() => setShowExpand(!showExpand)}
            >
              <Typography
                style={{
                  textTransform: 'uppercase',
                  color: '#aaa',
                  fontSize: 13,
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  userSelect: 'none',
                }}
              >
                {showExpand ? 'Hide' : 'Details'}
              </Typography>
              <Box position="relative" top={2}>
                {showExpand ? (
                  <ExpandLess style={{ fontSize: 20, color: '#aaa' }} />
                ) : (
                  <ExpandMore style={{ fontSize: 20, color: '#aaa' }} />
                )}
              </Box>
            </Box>
            <Button
              className={cx(classes.purchaseButton, {
                [classes.purchaseButtonActive]: isActive,
              })}
              variant="outlined"
              size="small"
              onClick={handleToggle}
            >
              <Box
                display="flex"
                flexDirection="row"
                gap="5px"
                alignItems="center"
              >
                {isActive ? <CheckRounded /> : <AddRounded />}
                <Typography
                  style={{
                    fontSize: 19,
                    fontWeight: 600,
                  }}
                >
                  {price
                    ? `${getCurrencySymbol(currency_code)}${price}`
                    : 'FREE'}
                </Typography>
              </Box>
            </Button>
          </Box>
          <Box
            style={{
              maxHeight: showExpand ? '400px' : 1,
              overflow: 'hidden',
              transition:
                'max-height .3s cubic-bezier(0.455, 0.03, 0.515, 0.955)',
            }}
          >
            <Box pb={1}>
              <Typography style={{ fontSize: 15, userSelect: 'none' }}>
                {short_pitch}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
